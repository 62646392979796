<template>
  <div class="page-row">
    <v-container class="category-block-header">
      <category-title :category="category" />
    </v-container>

    <category-block
      v-for="(config, idx) in category.categoryBlocks"
      :key="config.categoryBlockId"
      :config="config"
      :class="`category-block category-block-${idx}`"
    />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>
<script>
import category from "~/mixins/category";
import login from "~/mixins/login";
import get from "lodash/get";

import { mapActions } from "vuex";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

export default {
  name: "PageRow",
  mixins: [category, login],
  components: {
    CategoryTitle
  },
  data() {
    return {
      key: 1
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    })
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category.name
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      this.$t("meta.category.firstLevel.description", [this.category.name])
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            this.category.metaData?.category_info?.HEADER_IMAGE ||
            this.category.metaData?.category_info?.DESCRIPTION_IMAGE ||
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Zona.eu"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
